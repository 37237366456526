import Vue from 'vue';
import Vuex from 'vuex';
//import Axios from "axios";
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        confirmMessage: {
            show: false
        },
        notifyMessage: {
            show: false
        },
        token: {
            sid: '',
            name: '',
            trx: '',
            paymentDetail: {
                token: '',
                payment: false,
                status: '',
            }
        },
        copy: {},
        depositCheck: {state: false, token: ''},
        steps: {
            one: {
                error: false,
                now: true,
                waiting: false,
                approved: false
            },
            two: {
                error: false,
                now: false,
                waiting: true,
                approved: false
            },
            three: {
                error: false,
                now: false,
                waiting: true,
                approved: false
            },
        },
        errorSid: {
            error: false,
            waiting: false,
            message: ""
        },
        paymentDetail: {
            name: '',
            account_name: '',
            account_number: '',
            amount: '',
            bank_name: '',
            description: ''
        },
        siteName: '',
        api_trx: '',
        bank_name: '',
    },
    mutations: {
        setToken(state, data){
            state.token = data
        },
        setConfirmMessage(state, data){
            state.confirmMessage = data
        },
        setNotifyMessage(state, data){
            state.notifyMessage = data
        },
        setCopy(state, payLoad){
            state.copy = payLoad
        },
        setStep(state, payLoad){
            state.steps = payLoad
        },
        setErr(state, payLoad){
            state.errorSid = payLoad
        },
        setDepositCheck(state, payLoad){
            state.depositCheck = payLoad
        },
        setPaymentDetail(state, payLoad){
            state.paymentDetail = payLoad
        },
        setSiteName(state, payLoad){
            state.siteName = payLoad
        },
        setTrx(state, payLoad){
            state.api_trx = payLoad
        },
        setBankName(state, payLoad){
            state.bank_name = payLoad
        }
    },
    actions: {
        getToken(context, payLoad) {
            context.commit('setToken', payLoad)
        },
        getCopy(context, payLoad) {
            context.commit('setCopy', payLoad)
        },
        getConfirmMessage(context, payLoad) {
            context.commit('setConfirmMessage', payLoad)
        },
        getNotifyMessage(context, payLoad) {
            context.commit('setNotifyMessage', payLoad)
        },
        getSteps(context, payLoad) {
            context.commit('setStep', payLoad)
        },
        getErr(context, payload){
            context.commit('setErr', payload)
        },
        depositCheck(context, payload){
            context.commit('setDepositCheck', payload)
        },
        paymentDetail(context, payload){
            context.commit('setPaymentDetail', payload)
        },
        siteName(context, payload){
            context.commit('setSiteName', payload)
        },
        getTrx(context, payload){
            context.commit('setTrx', payload)
        },
        bankName(context, payLoad){
            context.commit('setBankName', payLoad)
        }

    },
    getters: {
        token: state => state.token,
        confirmMessage: state => state.confirmMessage,
        notifyMessage: state => state.notifyMessage,
        copy: state => state.copy,
        steps: state => state.steps,
        depositCheck: state => state.depositCheck,
        errorSid: state => state.errorSid,
        paymentDetail: state => state.paymentDetail,
        siteName: state => state.siteName,
        api_trx: state => state.api_trx,
        bank_name: state => state.bank_name
    },
});